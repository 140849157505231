/*eslint-disable no-console*/
import React, {
  createContext,
  ReactNode,
  useContext,
  useRef,
  useState,
} from 'react';
import { PriorityCustomerDTO } from '../../domain/priorityFieldVisit/PriorityCustomerDTO';
import { getPriorityCustomers } from '../../api/Customer';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getFormattedDate } from '../../utils/utils';

const EmptyCustomerDetails: PriorityCustomerDTO[] = [
  {
    priorityId: '',
    storeId: '',
    agreementNumber: '',
    fieldAction: '',
    specialOffer: '',
    subType: '',
    productType: '',
    displayOrder: '',
    lateDescription: '',
    daysLate: '',
    fpd7: '',
    agreementRV: '',
    description: '',
    extensionOffer: '',
    epoOffer: '',
    rateOffer: '',
    termOffer: '',
    priorWeekDelinquent: '',
    status: '',
    customer: {
      customerId: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      stateName: '',
      zip: '',
    },
  },
];

export interface PriorityFieldCustomerState {
  priorityFieldVisitCustomerDetails: PriorityCustomerDTO[];
  loading?: boolean;
  exportToXls: any;
  hasApiError: boolean;
}

export interface PriorityFieldDispatchState {
  fetchPriorityCustomerDetails: (storeId: string) => void;
  saveToXls: () => void;
}

export const PriorityFieldCustomerStateContext =
  createContext<PriorityFieldCustomerState>({} as PriorityFieldCustomerState);

export const PriorityFieldDispatchContext =
  createContext<PriorityFieldDispatchState>({} as PriorityFieldDispatchState);

const exportIndex = [
  4, 5, 6, 0, 1, 14, 15, 16, 17, 18, 19, 2, 7, 8, 3, 21, 22, 23, 24, 25, 26,
];

export const PriorityFieldProvider = (props: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>();
  const [hasApiError, setHasApiError] = useState(false);
  const exportToXls = useRef<HTMLTableElement | null>(null);
  const [
    priorityFieldVisitCustomerDetails,
    setPriorityFieldVisitCustomerDetails,
  ] = useState<PriorityCustomerDTO[]>(EmptyCustomerDetails);

  const filterColumns = (): any[] => {
    const rows =
      exportToXls &&
      exportToXls.current &&
      exportToXls.current.querySelectorAll('tr');

    // Create an array to hold filtered data
    const filteredData: any[] = [];

    if (rows) {
      rows.forEach((row) => {
        const cells = row.querySelectorAll('tr, *');

        const rowData: any[] = [];

        exportIndex.forEach((idx) => {
          rowData.push(cells[idx].textContent);
        });

        // Add the filtered row data to the filteredData array
        filteredData.push(rowData);
      });
    }
    return filteredData;
  };
  const saveToXls = () => {
    const filtered = filterColumns();
    if (exportToXls.current && filtered && filtered.length > 0) {
      const worksheet = XLSX.utils.aoa_to_sheet(filtered);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, getFormattedDate());

      const workbookBinary = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      const mefilename = `rpt-FVPriority.xlsx`;
      saveAs(
        new Blob([workbookBinary], { type: 'application/octet-stream' }),
        mefilename
      );
    }
  };
  const fetchPriorityCustomerDetails = async (storeId: string) => {
    setHasApiError(false);
    setLoading(true);
    setPriorityFieldVisitCustomerDetails(EmptyCustomerDetails);

    await getPriorityCustomers(storeId)
      .then((response) => {
        setPriorityFieldVisitCustomerDetails(response.priorityList);
      })
      .then((error) => console.log(error))
      .catch((eee) => {
        if (eee?.response?.status === 400) {
          console.log(eee);
          setHasApiError(false);
        } else {
          console.log(eee);
          setHasApiError(true);
        }
      })

      .finally(() => setLoading(false));
  };
  return (
    <PriorityFieldCustomerStateContext.Provider
      value={{
        priorityFieldVisitCustomerDetails,
        loading,
        hasApiError,
        exportToXls,
      }}
    >
      <PriorityFieldDispatchContext.Provider
        value={{
          fetchPriorityCustomerDetails,
          saveToXls,
        }}
      >
        {props.children}
      </PriorityFieldDispatchContext.Provider>
    </PriorityFieldCustomerStateContext.Provider>
  );
};

export const usePriorityFieldCustomerState = () =>
  useContext(PriorityFieldCustomerStateContext);

export const usePriorityFieldCustomerActions = () =>
  useContext(PriorityFieldDispatchContext);
