import React from 'react';
import { Grid, makeStyles, RACButton, RACCard } from '@rentacenter/racstrap';
import clsx from 'clsx';
import { usePriorityFieldCustomerActions } from '../../context/PriorityFieldVisit/PriorityFieldProvider';

const useStyles = makeStyles((theme: any) => ({
  footerRoot: {
    width: '100%',
    marginBottom: '0rem',
    display: 'block',
    position: 'fixed',
    bottom: '0',
    left: '0',
    zIndex: 1100,
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(0),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0rem',
  },
  cardBody: {
    flex: '0 0 auto',
    padding: '1rem 1rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.typography.pxToRem(4),
    padding: '1rem',
  },
  button: {
    height: theme.typography.pxToRem(43),
  },
}));

export const PriorityCustomerFooter = () => {
  const classes = useStyles();
  const { saveToXls } = usePriorityFieldCustomerActions();

  return (
    <div className={clsx(classes.footerRoot)}>
      <RACCard className={classes.card}>
        <div className={clsx(classes.cardBody)}>
          <div className={clsx(classes.row)}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                classes={{ root: classes.buttonContainer }}
              >
                <RACButton
                  className={clsx(classes.button)}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    saveToXls();
                  }}
                >
                  Save to Excel
                </RACButton>
              </Grid>
            </Grid>
          </div>
        </div>
      </RACCard>
    </div>
  );
};
