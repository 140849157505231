/* eslint-disable no-console */
import React, { useContext, useRef, useState } from 'react';
// import { Footer } from '../../common/Footer/Footer';

import {
  makeStyles,
  RACCOLOR,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';
import { PriorityFieldCustomerStateContext } from '../../context/PriorityFieldVisit/PriorityFieldProvider';
import clsx from 'clsx';
import { PriorityCustomerDTO } from '../../domain/priorityFieldVisit/PriorityCustomerDTO';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  ApiStateWrapper,
  Loader,
} from '../../common/ApiStateWrapper/ApiStateWrapper';

export const FULL_API_ERROR_MESSAGE =
  'Unable to fetch the data. Please try again later.';
export const INITIAL_OFFSET = 0;
export const LIST_API_LIMIT = 500;

const useClasses = makeStyles((theme: any) => ({
  customerListWrapper: {
    overflowX: 'auto',
    height: theme.typography.pxToRem(770),
  },
  header: {
    fontSize: '1rem',
    marginBottom: '.5rem',
  },
  cardBody: {
    padding: '.5rem',
    width: '100%',
    boxSizing: 'border-box',
  },
  checkbox: {
    padding: '0',
  },
  noLeftPadding: {
    paddingLeft: 0,
  },
  selected: {
    backgroundColor: 'none !important',
  },
  statusPadding: {
    width: '15%',
  },
  leftPadding21: {
    paddingLeft: theme.typography.pxToRem(21),
  },
  paddingTopBottom8: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  rightField: {
    marginRight: theme.typography.pxToRem(5),
  },
  hideIt: {
    display: 'none',
  },
  link: {
    color: RACCOLOR.BLUE_CRAYOLA,
    cursor: 'pointer',
  },
  contentHeight: {
    height: theme.typography.pxToRem(770),
  },
  tipRoot: {
    borderLeftWidth: theme.typography.pxToRem(6),
  },
}));

export const fieldVisitPriorityWrapperTestId =
  'fieldVisitPriorityWrapperTestId';

const tableHeaderList = [
  'Rank',
  'Customer',
  'DPD',
  'DPD Days',
  'Agreement',
  'Action',
  'Special Offer',
  'Product Type',
  'SubType',
  'Extension Offer',
  'EPO Offer',
  'Rate Offer',
  'Term Offer',
  'Status',
  'Phone Number',
  'addressLine1',
  'addressLine2',
  'city',
  'State',
  'zip',
  'fpd7',
  'Remaining Value',
  'Customer Type',
  'Extension Offer Description',
  'EPO Offer Description',
  'Rate Offer Description',
  'Tern Offer Description',
];

export const PriorityCustomerList = () => {
  const classes = useClasses();
  const colorIdx = useRef(0);
  const [offset, setOffset] = useState<number>(INITIAL_OFFSET);

  const {
    loading,
    hasApiError,
    exportToXls,
    priorityFieldVisitCustomerDetails,
  } = useContext(PriorityFieldCustomerStateContext);

  console.log('loading is ' + loading);
  console.log('hasApiError is ' + hasApiError);

  const renderTableHead = () => (
    <>
      {tableHeaderList.map((col, index) => {
        if (index === 11) {
          return (
            <RACTableCell
              key={index}
              padding="checkbox"
              classes={{
                root: clsx(classes.statusPadding),
              }}
            >
              {col}
            </RACTableCell>
          );
        } else if (index === 0) {
          return (
            <RACTableCell
              key={index}
              padding="checkbox"
              classes={{
                root: clsx(classes.leftPadding21, classes.paddingTopBottom8),
              }}
            >
              {col}
            </RACTableCell>
          );
        } else if (index > 13) {
          return (
            <RACTableCell
              key={index}
              padding="checkbox"
              classes={{
                root: clsx(classes.hideIt),
              }}
            >
              {col}
            </RACTableCell>
          );
        } else {
          return (
            <RACTableCell
              key={index}
              classes={{
                root: classes.paddingTopBottom8,
              }}
            >
              {col}
            </RACTableCell>
          );
        }
      })}
    </>
  );

  const fetchNextPage = () => {
    /*
    fetchNextPage is used by infinite scroll component
    the first fetch is handled by our component, therefore we are not letting the
    infinite scroll to trigger the first request.
    Handles the case when you apply the filters
    (offset set to 0 -> request is triggered, but infinite scroll is also triggering a request, because its data length is 0)
    */

    setOffset(offset + LIST_API_LIMIT);
  };

  const renderTableContent = () => (
    <>
      {priorityFieldVisitCustomerDetails?.map(
        (oneCustomer: PriorityCustomerDTO) => {
          return (
            <RACTableRow
              key={oneCustomer.priorityId}
              hover
              backgroundColor={
                colorIdx.current++ % 2 == 0
                  ? RACCOLOR?.WHITE
                  : RACCOLOR?.ALICE_BLUE
              }
            >
              <RACTableCell>{oneCustomer.displayOrder}</RACTableCell>
              <RACTableCell>
                {oneCustomer.customer.firstName} {oneCustomer.customer.lastName}
              </RACTableCell>

              <RACTableCell>{oneCustomer.lateDescription} </RACTableCell>
              <RACTableCell>{oneCustomer.daysLate} </RACTableCell>
              <RACTableCell>{oneCustomer.agreementNumber} </RACTableCell>
              <RACTableCell>{oneCustomer.fieldAction} </RACTableCell>
              <RACTableCell>{oneCustomer.specialOffer} </RACTableCell>
              <RACTableCell>{oneCustomer.productType} </RACTableCell>
              <RACTableCell>{oneCustomer.subType} </RACTableCell>
              <RACTableCell>{oneCustomer.extensionOffer} </RACTableCell>
              <RACTableCell>{oneCustomer.epoOffer} </RACTableCell>
              <RACTableCell>{oneCustomer.rateOffer} </RACTableCell>
              <RACTableCell>{oneCustomer.termOffer} </RACTableCell>
              <RACTableCell>{oneCustomer.status} </RACTableCell>
              <RACTableCell className={classes.hideIt}>
                {oneCustomer.customer.phoneNumber}
              </RACTableCell>
              <RACTableCell className={classes.hideIt}>
                {oneCustomer.customer.addressLine1}
              </RACTableCell>
              <RACTableCell className={classes.hideIt}>
                {oneCustomer.customer.addressLine2}
              </RACTableCell>
              <RACTableCell className={classes.hideIt}>
                {oneCustomer.customer.city}
              </RACTableCell>
              <RACTableCell className={classes.hideIt}>
                {oneCustomer.customer.stateName}
              </RACTableCell>
              <RACTableCell className={classes.hideIt}>
                {oneCustomer.customer.zip}
              </RACTableCell>
              <RACTableCell className={classes.hideIt}>
                {oneCustomer.fpd7}
              </RACTableCell>
              <RACTableCell className={classes.hideIt}>
                {oneCustomer.agreementRV}
              </RACTableCell>
              <RACTableCell className={classes.hideIt}>
                {oneCustomer.description}
              </RACTableCell>
              <RACTableCell className={classes.hideIt}>
                {oneCustomer.extensionOffer}
              </RACTableCell>
              <RACTableCell className={classes.hideIt}>
                {oneCustomer.epoOffer}
              </RACTableCell>
              <RACTableCell className={classes.hideIt}>
                {oneCustomer.rateOffer}
              </RACTableCell>
              <RACTableCell className={classes.hideIt}>
                {oneCustomer.termOffer}
              </RACTableCell>
            </RACTableRow>
          );
        }
      )}
    </>
  );

  return (
    <div
      className={clsx(classes.customerListWrapper)}
      data-testid={fieldVisitPriorityWrapperTestId}
    >
      <InfiniteScroll
        next={fetchNextPage}
        dataLength={5}
        hasMore={false}
        height={770}
        loader={loading ? <Loader /> : null}
      >
        <ApiStateWrapper
          // show loading only for the first request
          loading={loading}
          // show error only if the first request fails
          hasApiError={hasApiError}
          response={priorityFieldVisitCustomerDetails}
          noItemAdditionalText={''}
          successContent={
            <div ref={exportToXls}>
              <RACTable
                renderTableHead={renderTableHead}
                renderTableContent={renderTableContent}
                stickyHeader
              />
            </div>
          }
          classes={{
            loader: classes.contentHeight,
            apiError: classes.contentHeight,
            noItems: classes.contentHeight,
          }}
        />
      </InfiniteScroll>
    </div>
  );
};
